<template>
  <b-container fluid>
    <div class="justify-content-center my-2" v-show="!this.loading">
      <div
        v-if="!competencyYearsOptions || competencyYearsOptions.length <= 0"
        class="text-center"
      >
        <a>Nenhuma competência encontrada!</a>
      </div>

      <div class="d-flex mb-3 flex-wrap p-1">
        <div class="p-2 custom-periodo align-self-center">
          COMPETÊNCIA:
        </div>
        <div class="p-1 align-self-center">
          <b-form-select
            v-model="selectedOption"
            :options="competencyYearsOptions"
            disabled-field="notEnabled"
            size="lg"
            v-on:change="onChangeSelect"
            class="custom-select-head"
          />
        </div>
        <div class="p-2 ml-auto">
          <b-button
            class="m-3"
            variant="outline-primary"
            @click="onsubmit()"
            v-show="
              !this.loading &&
                competencyYearsOptions &&
                competencyYearsOptions.length > 0
            "
            :disabled="!this.visiblePdf"
          >
            <img src="@/assets/icons/download-outline.svg" /> Baixar PDF
          </b-button>
        </div>
      </div>
    </div>
    <div v-show="this.loading">
      <b-skeleton-table :rows="1" :columns="1"></b-skeleton-table>
    </div>
    <pdf-viewer
      v-if="this.visiblePdf"
      :getDocumentHandler="getDocumentHandler"
    />

    <SuccessModal
      description="Solicitação de alteração enviada!"
      buttonText="Ok, entendi!"
    />
  </b-container>
</template>

<script>
import { inject } from "@vue/composition-api";

import SuccessModal from "../../../../components/base-modals/SuccessModal.vue";
import { getCompetencyYears } from "../../../../services/competency-years/competency-years-services";
import { DocumentType } from "../../../../static-data/DocumentType.js";
import PdfViewer from "../../../../components/pdf-viewer/PdfViewer.vue";
import { downloadBase64File } from "../../../../services/pdf-download/pdf-download-services";
import { anchorBase64Download } from "../../../../utils/anchor-base64-download/anchor-base64-download";

export default {
  name: "report-income-tab",
  components: {
    SuccessModal,
    PdfViewer,
  },
  setup() {
    const globalLoading = inject("globalLoading");
    return { globalLoading };
  },
  data() {
    return {
      selectedOption: new Date().getFullYear(),
      selectOptions: [],
      competencyYearsOptions: [],
      loading: true,
      visiblePdf: false,
      downloadedFile: null,
      fileUrl: null,
      document: null,
    };
  },
  async mounted() {
    await this.getCompetenceYears();
  },
  methods: {
    async onChangeSelect() {
      this.globalLoading = true;
      try {
        this.visiblePdf = false;
        await this.getDocument();
        this.visiblePdf = true;
        this.globalLoading = false;
      } finally {
        this.globalLoading = false;
      }
    },
    onsubmit() {
      this.downloadPdf();
    },
    async getCompetenceYears() {
      try {
        this.loading = true;
        const crm = this.$store.getters.getUserName;

        const competencyOptions = await getCompetencyYears(
          crm,
          DocumentType.INCOME_REPORT
        );
        this.competencyYearsOptions = competencyOptions
          .map((c) => c.year)
          .sort((a, b) => b - a);
        if (
          !this.competencyYearsOptions ||
          this.competencyYearsOptions.length > 0
        ) {
          this.selectedOption = this.competencyYearsOptions[0];
        }
        await this.getDocument();
        this.visiblePdf = true;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error("Error on function getCompetenceYears: ", error);
      }
    },
    downloadPdf() {
      if (!this.downloadedFile) return;
      anchorBase64Download(
        this.$store.getters.isApp,
        "application/pdf",
        "imposto-de-renda.pdf",
        this.downloadedFile
      );
    },
    async getDocument() {
      const download = await this.downloadFile();
      this.downloadedFile = download;
      const result = atob(download);
      this.document = result;
      return result;
    },
    async getDocumentHandler() {
      return this.document;
    },
    async downloadFile() {
      try {
        const competency = this.selectedOption;
        const response = await downloadBase64File(
          this.$store.getters.getUserName,
          DocumentType.INCOME_REPORT,
          competency
        );
        this.fileUrl = response.fileUrl;
        return response.document;
      } catch (error) {
        console.error("Error on function downloadFile:", error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.view-container.container-fluid {
  display: flex;
  flex-direction: column;
  max-width: 100rem;
  height: 60vh;
  text-align: left;
}

.custom-periodo {
  color: var(--grey-1);
}
.custom-select-head {
  min-width: 10rem;
}
</style>
