import axios from "../http-client/axios";
import { OPETH_URL } from "../urls/base-urls";

export async function getCompetencyYears(crm, type) {
  const resp = await axios({
    url: `${OPETH_URL}/datedocument?crm=${crm}&type=${type}`,
    method: "get",
    data: {},
    headers: { "content-type": "application/json" },
  });
  const { dateList } = resp.data;
  return dateList;
}
